<div
  class="card"
  [class.border-0]="!showBorder()"
  [class.hoverable]="canGoToPage()"
  (click)="navigateToPage()"
  (keydown)="navigateToPage()"
  tabindex="-1">
  @if (titleTemplate() || title()) {
    <div
      class="card-header bg-secondary"
      [ngClass]="{ 'rounded border-0': !showBorder() }">
      @if (titleTemplate()) {
        <ng-container *ngTemplateOutlet="titleTemplate()!"></ng-container>
      } @else if (title()) {
        <div class="fw-bold text-center small">{{ title() }}</div>
      }
    </div>
  }
  <div class="card-body pt-4 pt-md-3">
    <ng-content />
  </div>
</div>
