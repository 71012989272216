import { Component, computed, inject, input } from '@angular/core';
import {
  BaseChartDirective,
  provideCharts,
  withDefaultRegisterables,
} from 'ng2-charts';
import { ChartConfiguration } from 'chart.js';
import { doughnutChartOptions } from './chart-configuration';
import { ScoreService } from '../../services/score.service';

@Component({
  selector: 'cp-rp-half-donut-chart',
  standalone: true,
  templateUrl: './half-donut-chart.component.html',
  styleUrl: './half-donut-chart.component.scss',
  imports: [BaseChartDirective],
  providers: [provideCharts(withDefaultRegisterables())],
})
export class HalfDonutChartComponent {
  private readonly _scoreService = inject(ScoreService);

  readonly measureId = input('root');
  readonly showScoreLabel = input(true);

  protected readonly doughnutChartOptions = doughnutChartOptions;
  protected readonly doughnutChartDatasets = computed(() => {
    return this.computeChartDataset(this.measureId());
  });
  protected readonly scoreBucket = computed(() => {
    return this._scoreService.getScoringBucketForScore(this.measureId());
  });

  private computeChartDataset(
    measureId: string,
  ): ChartConfiguration<'doughnut'>['data']['datasets'] {
    const buckets = this._scoreService.getScoringBuckets(measureId);
    const score = this._scoreService.getScore(measureId);

    const dataset: ChartConfiguration<'doughnut'>['data']['datasets'] = [
      { backgroundColor: [], data: [] },
    ];

    for (let i = 0; i < buckets.length; i++) {
      const bucket = buckets[i];
      dataset[0].data[i] = bucket.ceiling - bucket.floor;

      // @ts-expect-error Set color for the index
      dataset[0].backgroundColor[i] =
        bucket.scoringBucketId === score?.scoringBucketId
          ? '#6763B3'
          : '#e4e4e4';
    }

    return dataset;
  }
}
