import { SupportedAttributeTypes } from './supported-attribute-types';

/**
 * Represents the declaration of a component's attribute with its type, origin, and visibility.
 */
export interface ComponentAttributeDeclaration {
  /**
   * Value for the attribute
   */
  value: SupportedAttributeTypes;

  /**
   * Indicates that the value was specified by the user
   */
  isExplicit: boolean;

  /**
   * Indicates that the value has been defined by the system and not the user
   */
  isInternal: boolean;
}

/**
 * Creates a new component attribute declaration marked as internal.
 *
 * @param {SupportedAttributeTypes} value - The value associated with the internal attribute declaration.
 * @return {ComponentAttributeDeclaration} An object representing the internal attribute declaration with predefined properties.
 */
export function makeInternalAttributeDeclaration(
  value: SupportedAttributeTypes,
): ComponentAttributeDeclaration {
  return {
    value,
    isExplicit: false,
    isInternal: true,
  };
}

/**
 * Creates a declaration object for a component attribute.
 *
 * @param {SupportedAttributeTypes} value - The value of the attribute to be declared.
 * @param {boolean} isExplicit - Indicates if the attribute declaration is explicit.
 * @return {ComponentAttributeDeclaration} The constructed attribute declaration object.
 */
export function makeAttributeDeclaration(
  value: SupportedAttributeTypes,
  isExplicit: boolean,
): ComponentAttributeDeclaration {
  return {
    value,
    isExplicit,
    isInternal: false,
  };
}
