<div class="canvas-wrapper mx-auto">
  <canvas
    baseChart
    [datasets]="doughnutChartDatasets()"
    [options]="doughnutChartOptions"
    type="doughnut">
  </canvas>
</div>

@if (showScoreLabel()) {
  <div class="text-center chart-label">{{ scoreBucket()?.name }}</div>
}
