<div class="d-flex position-relative">
  <svg viewBox="0 0 234 234">
    <circle class="bg" cx="50%" cy="50%" r="48%" />
    <circle
      class="meter-1"
      cx="50%"
      cy="50%"
      r="48%"
      [ngStyle]="{
        strokeDasharray: chartConfig().progress * 220 + '% 720',
      }" />
  </svg>

  <div class="mask">
    @if (showScoreValue()) {
      <span class="score mb-1">
        {{ score()?.formattedScore }}
      </span>
    }
    @if (scoreBucket() && showScoreLabel()) {
      <span class="category">
        {{ scoreBucket()?.name }}
      </span>
    }
  </div>
</div>
