<cp-rp-card
  [title]="displayTitle()"
  [showBorder]="showBorder()"
  [goToPage]="{ page: goToPage(), measure: measureId }">
  @if (showBucketLabel()) {
    <h5 class="text-center mb-3">
      {{ measure.label }}
    </h5>
  }
  <div
    [class.horizontal]="
      breakpoints.current().largerThan('small') &&
      orientation() === 'horizontal' &&
      showBuckets()
    ">
    @if (type() === 'full') {
      <cp-rp-donut-chart
        class="mx-auto"
        [measureId]="measureId"
        [showScoreLabel]="showScoreLabel()"
        [showScoreValue]="showScoreValue()" />
    } @else {
      <cp-rp-half-donut-chart
        class="mx-auto"
        [measureId]="measureId"
        [showScoreLabel]="showScoreLabel()" />
    }

    @if (
      (type() === 'half' &&
        component.hasExplicitAttribute('showBuckets') &&
        showBuckets()) ||
      (type() === 'full' && showBuckets())
    ) {
      <div class="d-flex flex-column justify-content-center">
        <div class="scoring-buckets-wrapper">
          @for (
            bucket of scoringBuckets | orderBy: '-ceiling';
            track bucket.scoringBucketId
          ) {
            <span class="pe-2 text-truncate">{{ bucket.name }}</span>
            <span class="text-nowrap text-end">
              {{ bucket.floorFormatted }} - {{ bucket.ceilingFormatted }}
            </span>
          }
        </div>
      </div>
    }
  </div>
</cp-rp-card>
