import { TemplateComponentDef } from '../models/template/template-component-def';
import parse, { HTMLElement, Node } from 'node-html-parser';
import { AttributeDef } from '../models/components/attribute-def';
import { ReportComponentDef } from '../models/components/report-component-def';
import { reportComponents } from '../../report-components';
import { ObjectIndex } from '@compass/types';
import { SupportedAttributeTypes } from '../models/components/supported-attribute-types';
import {
  COMPONENT_CLASS_LIST,
  COMPONENT_DECLARATION_ATTR,
  COMPONENT_MEASURE_SCOPE_ATTR,
  COMPONENT_SUPPLEMENTAL_MEASURES,
  COMPONENT_TEMPLATE_ATTR,
} from '../models/components/global-attributes';
import { ComponentAttributeValidator } from '../models/components/components-attribute-validators';
import { toArray } from '@compass/helpers';
import { Logger } from '@compass/logging';
import {
  ComponentAttributeDeclaration,
  makeAttributeDeclaration,
  makeInternalAttributeDeclaration,
} from '../models/components/component-attribute-declaration';

/**
 * Parser of custom report templates
 */
export class TemplateParser {
  /**
   * Parses component template into a tree of {@link TemplateComponentDef}
   * @param template Template to parse
   */
  public static parse(template: string): TemplateComponentDef[] {
    const parsedTemplate = parse(template);

    return TemplateParser.fromNodes(parsedTemplate.childNodes);
  }

  private static fromNodes(nodes: Node[]): TemplateComponentDef[] {
    const components: TemplateComponentDef[] = [];

    // Iterate over each node in the template
    for (const node of nodes) {
      if (!(node instanceof HTMLElement)) continue;

      const compDef = reportComponents.find(c => c.tagName === node.rawTagName);

      // If we cannot find definition for the node then do nothing
      if (!compDef) continue;

      components.push(TemplateParser.getTemplateDef(node, compDef));
    }

    return components;
  }

  private static getTemplateDef(
    node: Node,
    compDef: ReportComponentDef,
  ): TemplateComponentDef {
    return {
      componentDef: compDef,
      children: TemplateParser.fromNodes(node.childNodes),
      attributes: TemplateParser.getAttributeDeclaration(
        compDef,
        node as HTMLElement,
      ),
    };
  }

  private static getAttributeDeclaration(
    compDef: ReportComponentDef,
    node: HTMLElement,
  ): ObjectIndex<ComponentAttributeDeclaration> {
    let attributes: ObjectIndex<ComponentAttributeDeclaration> =
      TemplateParser.getGlobalAttributes(node);

    // Check for unknown attributes
    this.warnOfInvalidAttributes(compDef, node);

    // If object can be rendered (has component) then only apply defined properties
    if (compDef.component !== undefined && compDef.attributes) {
      attributes = Object.assign(
        attributes,
        TemplateParser.getAttributesFromAttributeDef(compDef, node),
      );
    }
    // If object cannot be rendered (placeholder item) then apply all properties
    else if (compDef.component === undefined) {
      attributes = Object.assign(
        attributes,
        TemplateParser.getAllAttributes(node),
      );
    }

    return attributes;
  }

  private static getAllAttributes(
    node: HTMLElement,
  ): ObjectIndex<SupportedAttributeTypes> {
    const attributes: ObjectIndex<SupportedAttributeTypes> = {};

    for (const attrName in node.attributes) {
      const value = node.getAttribute(attrName);

      attributes[attrName] = TemplateParser.getAttributeValue('string', value);
    }

    return attributes;
  }

  private static warnOfInvalidAttributes(
    compDef: ReportComponentDef,
    node: HTMLElement,
  ): void {
    for (const attrName in node.attributes) {
      if (
        compDef.component !== undefined &&
        compDef.attributes !== undefined &&
        attrName !== 'measure' &&
        attrName !== 'class' &&
        !Object.keys(compDef.attributes).some(
          a => a.toLowerCase() === attrName.toLowerCase(),
        )
      ) {
        Logger.instance.warn(
          `Attribute '${attrName}' was defined in template, but it is not a know attribute of '${
            compDef.component?.name ?? compDef.tagName
          }'.`,
        );
      }
    }
  }

  private static getAttributesFromAttributeDef(
    componentDef: ReportComponentDef,
    node: HTMLElement,
  ): ObjectIndex<ComponentAttributeDeclaration> {
    const attributes: ObjectIndex<ComponentAttributeDeclaration> = {};

    for (const attrName in componentDef.attributes) {
      const attr = componentDef.attributes[attrName];
      const attrDef = typeof attr === 'string' ? undefined : attr;
      const attrRawValue = node.getAttribute(attrName);
      const attrType = typeof attr === 'string' ? attr : attr.type;
      const attrValue = TemplateParser.getAttributeValue(
        attrType,
        attrRawValue,
        attrDef,
      );

      // If attribute has validator defined...
      if (!TemplateParser.isAttributeValid(attrValue, attrDef?.validator)) {
        Logger.instance.warn(
          `Value '${attrValue}' was invalid for attribute '${attrName}' within ${componentDef.tagName} template. It will not be bound.`,
        );
        continue;
      }

      // Everything passed - set the attribute on the object
      attributes[attrName] = makeAttributeDeclaration(
        attrValue,
        node.hasAttribute(attrName),
      );
    }

    return attributes;
  }

  private static isAttributeValid(
    attrValue: SupportedAttributeTypes,
    validators?: ComponentAttributeValidator | ComponentAttributeValidator[],
  ): boolean {
    for (const validator of toArray(validators)) {
      if (!validator(attrValue)) return false;
    }

    // Everything passed - it is valid
    return true;
  }

  private static getGlobalAttributes(
    node: HTMLElement,
  ): ObjectIndex<ComponentAttributeDeclaration> {
    const attributes: ObjectIndex<ComponentAttributeDeclaration> =
      TemplateParser.getMeasureAttributes(node);

    attributes[COMPONENT_TEMPLATE_ATTR] = makeInternalAttributeDeclaration(
      node.innerHTML,
    );
    attributes[COMPONENT_DECLARATION_ATTR] = makeInternalAttributeDeclaration(
      node.outerHTML,
    );
    attributes[COMPONENT_CLASS_LIST] = makeInternalAttributeDeclaration(
      node.classNames,
    );

    return attributes;
  }

  private static getMeasureAttributes(
    node: HTMLElement,
  ): ObjectIndex<ComponentAttributeDeclaration> {
    const attributes: ObjectIndex<ComponentAttributeDeclaration> = {};
    const measures = node
      .getAttribute('measure')
      ?.split(',')
      .map((m: string) => m.trim());

    if (!measures) return attributes;

    attributes[COMPONENT_MEASURE_SCOPE_ATTR] = makeInternalAttributeDeclaration(
      measures[0],
    );

    if (measures.length > 1) {
      attributes[COMPONENT_SUPPLEMENTAL_MEASURES] =
        makeInternalAttributeDeclaration(
          measures.slice(1 - measures.length).join(','),
        );
    }

    return attributes;
  }

  private static getAttributeValue(
    type: string,
    rawValue?: string,
    attrDef?: AttributeDef,
  ): SupportedAttributeTypes {
    switch (type) {
      case 'boolean':
        return TemplateParser.getBooleanAttribute(rawValue, attrDef);
      case 'string':
        return TemplateParser.getStringAttribute(rawValue, attrDef);
      case 'number':
        return TemplateParser.getNumberAttribute(rawValue, attrDef);
      default:
        throw new Error(`Attribute type of '${type}' is not implemented.`);
    }
  }

  private static getNumberAttribute(
    rawValue?: string,
    attrDef?: AttributeDef,
  ): number {
    const parsedNumber = rawValue ? Number.parseFloat(rawValue) : Number.NaN;

    if (!Number.isNaN(parsedNumber)) return parsedNumber;
    if (typeof attrDef?.defaultValue === 'number') return attrDef.defaultValue;

    return 0;
  }

  private static getBooleanAttribute(
    rawValue?: string,
    attrDef?: AttributeDef,
  ): boolean {
    const rawValueLowerCase = rawValue?.toLowerCase();

    if (rawValueLowerCase === 'false') return false;
    if (rawValueLowerCase === 'true' || rawValueLowerCase === '') return true;
    if (typeof attrDef?.defaultValue === 'boolean') return attrDef.defaultValue;

    return true;
  }

  private static getStringAttribute(
    rawValue?: string,
    attrDef?: AttributeDef,
  ): string {
    if (rawValue) return rawValue;
    if (typeof attrDef?.defaultValue === 'string') return attrDef.defaultValue;

    return '';
  }
}
