import { appVersion } from '../../app-version';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  SpinnerComponent,
  HtmlRendererComponent,
  SizeSyncDirective,
} from '@compass/ui';
import { appConfig } from '../environment/environment';
import { CompassHttpModule } from '@compass/http';
import {
  LoggingModule,
  ConsoleLoggingProvider,
  Logger,
  logInitMessage,
  logDeviceMessage,
} from '@compass/logging';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { MainComponent } from './@pages/main/main.component';
import { ReportViewComponent } from './@pages/report-view/report-view.component';
import { PublicReportComponent } from './shared/public-components/public-report-component';
import { ComponentRendererComponent } from './shared/components/component-renderer/component-renderer.component';
import { ReportRendererComponent } from './shared/components/report-renderer/report-renderer.component';
import { HeaderComponent } from './shared/public-components/header/header.component';
import { PageMenuComponent } from './shared/public-components/page-menu/page-menu.component';
import { PageComponent } from './shared/public-components/page/page.component';
import { LabelChartComponent } from './shared/public-components/label-chart/label-chart.component';
import { TemplateComponent } from './shared/public-components/template/template.component';
import { LayoutComponent } from './shared/public-components/layout/layout.component';
import {
  NgbAccordionModule,
  NgbDropdownModule,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import {
  NgArrayPipesModule,
  NgMathPipesModule,
  NgStringPipesModule,
} from 'ngx-pipes';
import { AnswersComponent } from './shared/public-components/answers/answers.component';
import { QuestionAnswerComponent } from './shared/components/question-answer/question-answer.component';
import { AccordionComponent } from './shared/components/accordion/accordion.component';
import { AccordionItemComponent } from './shared/components/accordion-item/accordion-item.component';
import { AccordionContentDirective } from './shared/directives/accordion-content.directive';
import { AccordionChartDirective } from './shared/directives/accordion-chart.directive';
import {
  IndexLetterPipe,
  SafeHtmlPipe,
  ToStringPipe,
  ReplacePipe,
  PhonePipe,
} from '@compass/pipes';
import { FormsModule } from '@angular/forms';
import { MeasureComponent } from './shared/public-components/measure/measure.component';
import { TagComponent } from './shared/public-components/tag/tag.component';
import { ScoreChartComponent } from './shared/components/score-chart/score-chart.component';
import { MeasureChartComponent } from './shared/public-components/measure-chart/measure-chart.component';
import { FocusableDirective } from './shared/directives/focusable.directive';
import { MeasureScoreChartComponent } from './shared/components/measure-score-chart/measure-score-chart.component';
import { ElementRegistry } from '@compass/elements';
import { MathComponent } from '@compass/math';
import { EmbeddedComponent } from './@pages/embedded/embedded.component';
import { InfoComponent } from './shared/public-components/info/info.component';
import {
  ApplicationEnvironment,
  ApplicationEnvironmentModule,
  DeviceInfoService,
} from '@compass/environment';
import { ChartScaleComponent } from './shared/components/chart-scale/chart-scale.component';
import { DividerComponent } from './shared/public-components/divider/divider.component';
import { ScaleModule } from '@compass/scaling';
import { ValidationModule } from '@compass/validation';
import { ReportVimeoPlayerComponent } from './shared/public-components/report-vimeo-player/report-vimeo-player.component';
import { VimeoPlayerComponent } from '@compass/video';
import { KeycloakAngularModule } from 'keycloak-angular';
import { AccordionToggleButtonComponent } from './shared/public-components/accordion-toggle-button/accordion-toggle-button.component';
import { BuilderComponent } from './@pages/builder/builder.component';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { CardComponent } from './shared/components/card/card.component';

@NgModule({
  declarations: [
    AppComponent,
    PublicReportComponent,
    MainComponent,
    ReportViewComponent,
    ComponentRendererComponent,
    ReportRendererComponent,
    HeaderComponent,
    PageMenuComponent,
    PageComponent,
    TemplateComponent,
    LayoutComponent,
    AnswersComponent,
    QuestionAnswerComponent,
    AccordionComponent,
    AccordionItemComponent,
    AccordionContentDirective,
    AccordionChartDirective,
    MeasureComponent,
    TagComponent,
    ScoreChartComponent,
    MeasureChartComponent,
    FocusableDirective,
    MeasureScoreChartComponent,
    EmbeddedComponent,
    InfoComponent,
    ChartScaleComponent,
    DividerComponent,
    ReportVimeoPlayerComponent,
    AccordionToggleButtonComponent,
    BuilderComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SpinnerComponent,
    CompassHttpModule.forRoot(appConfig.baseApiUrl),
    LoggingModule.forRoot(
      [new ConsoleLoggingProvider(appConfig.appName)],
      appConfig.minLogLevel,
    ),
    NgbTooltip,
    NgbDropdownModule,
    PhonePipe,
    NgArrayPipesModule,
    NgbAccordionModule,
    IndexLetterPipe,
    FormsModule,
    NgMathPipesModule,
    HtmlRendererComponent,
    NgStringPipesModule,
    ReplacePipe,
    ApplicationEnvironmentModule.forRoot({
      environment: appConfig.environment,
      version: appVersion,
      name: appConfig.appName,
    }),
    SafeHtmlPipe,
    ToStringPipe,
    ScaleModule,
    SizeSyncDirective,
    ValidationModule,
    VimeoPlayerComponent,
    KeycloakAngularModule,
    MonacoEditorModule.forRoot(),
    CardComponent,
    LabelChartComponent,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class AppModule {
  constructor(
    private readonly _elementRegistry: ElementRegistry,
    private readonly _appEnv: ApplicationEnvironment,
    private readonly _logger: Logger,
    private readonly _deviceInfo: DeviceInfoService,
  ) {
    this.registerCustomElements();
    this.logStartMessage();
  }

  private logStartMessage(): void {
    logInitMessage(this._appEnv);
    logDeviceMessage(this._deviceInfo.browser, this._deviceInfo.device);
  }

  private registerCustomElements(): void {
    this._elementRegistry.register('cp-math', MathComponent);
    this._elementRegistry.register('cp-vimeo', ReportVimeoPlayerComponent);
  }
}
