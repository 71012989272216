import {
  Component,
  computed,
  contentChild,
  inject,
  input,
  TemplateRef,
} from '@angular/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { PageService } from '../../services/page.service';

export type PageWithMeasureNavigation = {
  page: string;
  measure: string;
};

@Component({
  selector: 'cp-rp-card',
  standalone: true,
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss',
  imports: [NgTemplateOutlet, NgClass],
})
export class CardComponent {
  private readonly _pageService = inject(PageService);

  protected readonly titleTemplate =
    contentChild<TemplateRef<unknown>>('titleTemplate');
  protected readonly canGoToPage = computed(() => {
    const currentValue = this.goToPage();

    return currentValue?.page && currentValue?.measure;
  });

  readonly showBorder = input(true);
  readonly title = input('');
  readonly goToPage = input<PageWithMeasureNavigation>();

  protected navigateToPage(): void {
    if (!this.canGoToPage()) return;

    const currentValue = this.goToPage();

    if (!currentValue) return;

    this._pageService.tryShow(currentValue.page, currentValue.measure);
  }
}
