import { Component, computed, input } from '@angular/core';
import { PublicReportComponent } from '../public-report-component';
import { Breakpoints } from '@compass/environment';
import { TemplateService } from '../../services/template.service';
import { CardComponent } from '../../components/card/card.component';
import { NgArrayPipesModule } from 'ngx-pipes';
import { DonutChartComponent } from '../../components/donut-chart/donut-chart.component';
import { HalfDonutChartComponent } from '../../components/half-donut-chart/half-donut-chart.component';

@Component({
  selector: 'cp-rp-label-chart',
  standalone: true,
  templateUrl: './label-chart.component.html',
  styleUrls: ['./label-chart.component.scss'],
  imports: [
    CardComponent,
    NgArrayPipesModule,
    DonutChartComponent,
    HalfDonutChartComponent,
  ],
})
export class LabelChartComponent extends PublicReportComponent {
  readonly orientation = input<'vertical' | 'horizontal'>('vertical');
  readonly goToPage = input('');
  readonly showBucketLabel = input(false);
  readonly showScoreLabel = input(true);
  readonly showTitle = input(true);
  readonly title = input('Alignment');
  readonly showBorder = input(true);
  readonly showBuckets = input(true);
  readonly type = input<'full' | 'half'>('full');
  readonly showScoreValue = input(true);

  protected readonly displayTitle = computed(() => {
    if (!this.showTitle() || !this.title()) {
      return '';
    }

    return this._template.render(this.title(), this.measureId);
  });

  constructor(
    protected readonly breakpoints: Breakpoints,
    private readonly _template: TemplateService,
  ) {
    super();
  }
}
