import { Component, Input, OnInit } from '@angular/core';
import { PublicReportComponent } from '../public-report-component';
import { Measure } from '../../models/report/measure';
import { TemplateService } from '../../services/template.service';
import { SortOrder } from '~/shared/models/sorting/sort-order';
import {
  MeasureSort,
  sortMeasures,
} from '~/shared/models/sorting/measure-sort';
import { Score } from '~/shared/models/report/score';

@Component({
  selector: 'cp-rp-measure',
  templateUrl: './measure.component.html',
})
export class MeasureComponent extends PublicReportComponent implements OnInit {
  protected measures: Measure[] = [];

  @Input()
  title?: string;

  @Input()
  description: string = '';

  @Input()
  showSelf: boolean = false;

  @Input()
  showChildren: boolean = true;

  @Input()
  showChart: boolean = true;

  @Input()
  showChartValue: boolean = true;

  @Input()
  showChartTails: boolean = true;

  @Input()
  chartTailStart?: string;

  @Input()
  chartTailEnd?: string;

  @Input()
  collapsed: boolean = false;

  @Input()
  sortOrder: SortOrder = 'asc';

  @Input()
  sortBy: MeasureSort = 'sortOrder';

  constructor(protected readonly templateService: TemplateService) {
    super();
  }

  ngOnInit(): void {
    this.measures = this.getMeasuresToShow();
  }

  private getMeasuresToShow(): Measure[] {
    // Get the main measure
    let measures = this.measureService.getMeasures(
      this.measureId,
      this.showSelf,
    );

    // Collect all other measures
    for (const supMeasure of this.component.supplementalMeasures) {
      measures.push(
        ...this.measureService.getMeasures(supMeasure, this.showSelf),
      );
    }

    let scores: Score[] = [];

    measures = this.showChildren
      ? measures
      : measures.filter(
          m =>
            m.measureId === this.measureId ||
            this.component.supplementalMeasures.includes(m.measureId),
        );

    if (this.sortBy === 'score') {
      scores = this.measureService.getScoresForMeasures(
        measures.map(m => m.measureId),
      );
    }

    return sortMeasures(measures, this.sortBy, this.sortOrder, scores);
  }
}
