import { Injectable } from '@angular/core';
import { ScoringBucket } from '../models/report/scoring-bucket';
import { Score } from '../models/report/score';
import { ReportService } from './report.service';

/**
 * Service for handling scoring-related functionalities, such as
 * retrieving scoring buckets and scores associated with specific measures.
 * Uses the `ReportService` to access report data.
 */
@Injectable({
  providedIn: 'root',
})
export class ScoreService {
  constructor(private readonly _reportService: ReportService) {}

  /**
   * Retrieves a scoring bucket by its unique identifier.
   *
   * @param {string} scoringBucketId - The unique identifier of the scoring bucket to retrieve.
   * @return {ScoringBucket|undefined} The scoring bucket object if found, otherwise undefined.
   */
  getScoringBucket(scoringBucketId: string): ScoringBucket | undefined {
    return this._reportService.reportData?.scoring.scoringBuckets.find(
      sb => sb.scoringBucketId === scoringBucketId,
    );
  }

  /**
   * Retrieves the scoring buckets based on the provided measure ID.
   *
   * @param {string} [measureId] - Optional. The measure ID used to filter the scoring buckets.
   *                               If not provided, all scoring buckets are returned.
   * @return {ScoringBucket[]} An array of scoring buckets. If a measure ID is specified,
   *                           only the buckets matching that measure ID are returned.
   */
  getScoringBuckets(measureId?: string): ScoringBucket[] {
    if (!this._reportService.reportData) {
      return [];
    }

    if (!measureId) {
      return this._reportService.reportData.scoring.scoringBuckets;
    }

    return this._reportService.reportData.scoring.scoringBuckets.filter(
      sb => sb.measureId === measureId,
    );
  }

  /**
   * Retrieves the scoring bucket corresponding to the score for a given measure ID.
   *
   * @param measureId The unique identifier of the measure whose scoring bucket is to be retrieved.
   * @return The scoring bucket associated with the measure's score, or undefined if no score is found.
   */
  getScoringBucketForScore(measureId: string): ScoringBucket | undefined {
    const score = this.getScore(measureId);

    if (!score) {
      return undefined;
    }

    return this.getScoringBucket(score.scoringBucketId);
  }

  /**
   * Retrieves the score associated with the specified measure ID.
   *
   * @param {string} measureId - The identifier of the measure for which the score is to be retrieved.
   * @return {Score | undefined} The score object corresponding to the given measure ID,
   * or undefined if no matching score is found.
   */
  getScore(measureId: string): Score | undefined {
    return this._reportService.reportData?.completed.scores.find(
      s => s.measureId === measureId,
    );
  }
}
