<div class="no-container-padding px-4 px-md-5 py-3">
  <div class="d-flex justify-content-between">
    <div>
      <!-- Left side -->
      <h2 *ngIf="name" class="mb-2 mb-md-1 pb-1 pb-md-0 participant-title">
        {{ reportData.participant.nameFull }}
      </h2>
      <h5 class="fw-light mb-0 mb-md-3 participant-subtitle">
        {{ reportData.assessment.name }}
      </h5>

      <div
        *ngIf="breakpoints.current().largerThan('small')"
        [ngbTooltip]="
          'Completed on ' +
          (reportData.status.completedOn | date) +
          ' in ' +
          reportData.completed.timing.timeToComplete +
          '.'
        ">
        <span class="circle me-1">
          <i class="fa-light fa-calendar"></i>
        </span>
        <span class="me-1">
          {{ reportData.status.completedOn | date }}
        </span>
        <span class="circle me-1">
          <i class="fa-light fa-clock"></i>
        </span>
        <span>
          {{ reportData.completed.timing.timeToComplete }}
        </span>
      </div>
    </div>
    <!-- Right side -->
    <!-- Desktop right nav -->
    <ng-container *ngIf="breakpoints.current().largerThan('small')">
      <!-- Screen view -->
      <div class="d-flex align-items-center d-print-none">
        <a
          class="btn btn-outline-secondary btn-circle d-inline-flex me-3"
          *ngIf="email"
          [href]="'mailto:' + reportData.participant.email"
          [ngbTooltip]="reportData.participant.email">
          <i class="fa fa-light fa-envelope"></i>
        </a>
        <a
          *ngIf="reportData.participant.phone && phone"
          [ngbTooltip]="reportData.participant.phone | phone"
          class="btn btn-outline-secondary btn-circle d-inline-flex me-3"
          [href]="'tel:' + reportData.participant.phone">
          <i class="fa fa-light fa-phone"></i>
        </a>
        <button
          class="btn btn-outline-secondary btn-circle d-inline-flex"
          ngbTooltip="Print"
          (click)="print()">
          <i class="fa fa-light fa-print"></i>
        </button>
      </div>
      <!-- Print view -->
      <div
        class="d-flex d-none d-print-flex flex-column justify-content-center">
        <div *ngIf="reportData.participant.email && email">
          <i class="fa fa-light fa-envelope me-2"></i>
          <span>
            {{ reportData.participant.email }}
          </span>
        </div>
        <div *ngIf="reportData.participant.phone && phone">
          <i class="fa fa-light fa-phone me-2"></i>
          <span>
            {{ reportData.participant.phone | phone }}
          </span>
        </div>
      </div>
    </ng-container>
    <!-- Mobile right nav -->
    <ng-container *ngIf="breakpoints.current().smallerThan('medium')">
      <div class="d-flex align-items-center" ngbDropdown>
        <button
          class="btn btn-outline-secondary btn-circle"
          type="button"
          ngbDropdownToggle>
          <i class="fa-solid fa-ellipsis-vertical"></i>
        </button>
        <ul class="pt-0 pb-2" ngbDropdownMenu>
          <div>
            <li
              ngbDropdownItem
              class="py-3 mb-2 d-flex align-items-center completion-info">
              <span class="circle me-2">
                <i class="fa-light fa-calendar"></i>
              </span>
              <span class="me-4">{{
                reportData.status.completedOn | date
              }}</span>
              <span class="circle me-2">
                <i class="fa-light fa-clock"></i>
              </span>
              <span>{{ reportData.completed.timing.timeToComplete }}</span>
            </li>
            <li
              ngbDropdownItem
              class="py-0 d-flex align-items-center"
              *ngIf="email">
              <a class="text-decoration-none py-2">
                <i role="button" class="fa fa-light fa-envelope me-2"></i>
                Email: {{ reportData.participant.email }}
              </a>
            </li>
            <li
              ngbDropdownItem
              class="py-0 d-flex align-items-center"
              *ngIf="reportData.participant.phone && phone">
              <a class="text-decoration-none py-2">
                <i role="button" class="fa fa-light fa-phone me-2"></i>
                Phone: {{ reportData.participant.phone | phone }}
              </a>
            </li>
          </div>
          <li
            ngbDropdownItem
            class="py-2 d-flex align-items-center cursor-pointer">
            <i role="button" class="fa fa-light fa-print me-2"></i> Print report
          </li>
        </ul>
      </div>
    </ng-container>
  </div>
</div>
