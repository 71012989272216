import { Component, computed, inject, input } from '@angular/core';
import { NgClass, NgStyle } from '@angular/common';
import { ScoreService } from '../../services/score.service';
import { ChartConfigProvider } from '../../services/chart-config-provider.service';

@Component({
  selector: 'cp-rp-donut-chart',
  standalone: true,
  templateUrl: './donut-chart.component.html',
  styleUrl: './donut-chart.component.scss',
  imports: [NgStyle, NgClass],
})
export class DonutChartComponent {
  private readonly _scoreService = inject(ScoreService);
  private readonly _chartConfig = inject(ChartConfigProvider);

  readonly measureId = input('root');
  readonly showScoreLabel = input(true);
  readonly showScoreValue = input(true);

  readonly scoreBucket = computed(() => {
    return this._scoreService.getScoringBucketForScore(this.measureId());
  });
  readonly score = computed(() => {
    return this._scoreService.getScore(this.measureId());
  });
  readonly chartConfig = computed(() => {
    return this._chartConfig.getConfig(this.measureId());
  });
}
