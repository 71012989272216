import { ChartConfiguration } from 'chart.js';

export const doughnutChartOptions: ChartConfiguration<'doughnut'>['options'] = {
  rotation: -90,
  circumference: 180,
  cutout: '16%',
  maintainAspectRatio: false,
  responsive: true,
  backgroundColor: '#e4e4e4',
  datasets: {
    doughnut: {
      borderWidth: 5,
    },
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
    legend: {
      display: false,
    },
  },
  animation: {
    duration: 1500,
  },
};
